body {
  --dark-blue: #003048;
  --blue: #006086;
  --dark-gray: #2B2A29;
  --gray: #282828;
  --light-gray: #f2f2f2;
  --lighter-gray: #f9f9f9;
  --white: #ffffff;
  --black: #000000;
  --green: #95C032;
  --red: #f44336;
}

$app-primary: (
  50 : #e0ecf0,
  100 : #b3cfdb,
  200 : #80b0c3,
  300 : #4d90aa,
  400 : #267898,
  500 : #006086,
  600 : #00587e,
  700 : #004e73,
  800 : #004469,
  900 : #003356,
  A100 : #88c5ff,
  A200 : #55acff,
  A400 : #2294ff,
  A700 : #0887ff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$app-accent: (
  50 : #e0f4fb,
  100 : #b3e3f5,
  200 : #80d1ef,
  300 : #4dbfe8,
  400 : #26b1e3,
  500 : #00a3de,
  600 : #009bda,
  700 : #0091d5,
  800 : #0088d1,
  900 : #0077c8,
  A100 : #f1f8ff,
  A200 : #bee1ff,
  A400 : #8bc9ff,
  A700 : #71beff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);
