body {
  overflow: overlay;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--light-gray);
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--gray);
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--gray);
}

section {
  max-width: 1360px;
  padding: 16px 12px;
  margin: 0 auto;
  line-height: 1.5;
}

h1 {
  color: var(--blue);
  font-size: 28px;
  font-weight: 600;
}

p {
  color: var(--gray);
  font-size: 18px;
  font-weight: 400;
  text-align: left;
}

img {
  max-width: 100%;
  // cursor: pointer;
}

li {
  font-size: 18px;
  color: var(--gray);
  line-height: 1.5 !important;
}

.banner_top {
  text-align: center;
  width: 100%;
}

main {
  min-height: 80vh;
}

.lSGallery img {
  height: 75px !important;
  object-fit: cover;
}

.splide__arrow {
  background: #006086 !important;

  svg {
    fill: white !important;
  }
}

@media screen and (min-width: 576px) {
  .splide__arrow--prev {
    left: -3rem;
  }

  .splide__arrow--next {
    right: -3rem;
  }
}

.btn-primary,
.btn-secondary {
  border: 0px !important;
}

.lSAction>a {
  background-repeat: no-repeat !important;
  width: 40px !important;
  height: 40px !important;
  background-position: 0 0 !important;
}

.lSAction>.lSPrev {
  background-image: url('/assets/arrow_left.svg');
}

.lSAction>.lSNext {
  background-image: url('/assets/arrow_right.svg');
}

.whatsapp-button {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 25px;
  background-color: #25d366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
  text-decoration: none;

  &:hover {
    color: #FFF;
  }

  i {
    font-size: 2rem;
  }
}

.p-dialog-mask {
  z-index: 1000;
}

ul.dash {
  list-style: none;
  margin-left: 0;
  padding-left: 1em;
}
ul.dash > li:before {
  display: inline-block;
  content: "-";
  width: 1em;
  margin-left: -1em;
}